var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Divider, TextField, Typography, styled, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';
import { Fragment, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../hooks/useform';
import { Popover } from '../menupopover';
import { DEFAULT_CUSTOM_QUICK_DAY, PATTERN_LAST_DATE_FIELD, QUICK_SELECT } from './constants';
var StyledDateRangePicker = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(5),
        '.react-datepicker': {
            fontSize: theme.typography.body2.fontSize,
            background: 'inherit',
            display: 'inline-flex',
            flexWrap: 'nowrap',
            gap: theme.spacing(9.25),
            border: 0,
            '.react-datepicker__header': {
                padding: 0,
                border: 0,
                marginBottom: theme.spacing(2.5),
                background: 'inherit',
                '.react-datepicker__current-month': {
                    fontSize: theme.typography.body3.fontSize,
                    textTransform: 'uppercase',
                    color: theme.palette.text.primary,
                },
                '.react-datepicker__day-names': {
                    display: 'none',
                },
            },
            '.react-datepicker__navigation': {
                top: theme.spacing(-1),
            },
            '.react-datepicker__month': {
                margin: 0,
                boxSizing: 'content-box',
                '.react-datepicker__day': {
                    margin: theme.spacing(0.5, 0),
                    padding: theme.spacing(0, 0.6),
                    borderRadius: 0,
                    '&:not(.react-datepicker__day--disabled)': {
                        color: theme.palette.text.primary,
                        '&.react-datepicker__day--outside-month': {
                            color: theme.palette.text.disabled,
                            '&:empty': {
                                backgroundColor: 'transparent!important',
                            },
                        },
                    },
                    '&:not(.react-datepicker__day--in-range)': {
                        '&.react-datepicker__day--in-selecting-range': {
                            '&.react-datepicker__day--selecting-range-start': {
                                borderTopLeftRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                                borderBottomLeftRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                            },
                            '&.react-datepicker__day--selecting-range-end': {
                                borderTopRightRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                                borderBottomRightRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                            },
                        },
                        '&:not(.react-datepicker__day--disabled,.react-datepicker__day--in-selecting-range):hover': {
                            borderRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                            backgroundColor: theme.palette.primary.main,
                        },
                    },
                    '&--range-start:not(.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start))': {
                        borderTopLeftRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                        borderBottomLeftRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                    },
                    '&--range-end:not(.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-end))': {
                        borderTopRightRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                        borderBottomRightRadius: theme.typography.pxToRem(theme.shape.borderRadius * 1.5),
                    },
                    '&--selected, &.react-datepicker__day--in-selecting-range, &.react-datepicker__day--in-range': {
                        color: theme.palette.primary.contrastText,
                        backgroundColor: theme.palette.primary.main,
                    },
                    '&--in-selecting-range': {
                        '&:not(.react-datepicker__day--in-range,.react-datepicker__month-text--in-range,.react-datepicker__quarter-text--in-range,.react-datepicker__year-text--in-range)': {
                            backgroundColor: "".concat(theme.palette.primary.main, "80"),
                        },
                    },
                    '&--in-range': {
                        '&:not(.react-datepicker__day--in-selecting-range,.react-datepicker__month-text--in-selecting-range,.react-datepicker__quarter-text--in-selecting-range,.react-datepicker__year-text--in-selecting-range)': {
                            color: theme.palette.primary.contrastText,
                            backgroundColor: "".concat(theme.palette.primary.main, "80"),
                            fontWeight: theme.typography.fontWeightMedium,
                        },
                    },
                    '&--keyboard-selected:not(.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range)': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
    });
});
var StyledQuickOption = styled(Button)(function (_a) {
    var theme = _a.theme;
    return ({
        padding: theme.spacing(0, 3),
        marginLeft: "".concat(theme.spacing(-3), "!important"),
        justifyContent: 'flex-start',
    });
});
var StyledLastDaysContainer = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(2.5),
        marginTop: theme.spacing(4),
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.primary,
        cursor: 'pointer',
    });
});
var StyledLabel = styled(Typography)(function (_a) {
    var theme = _a.theme;
    return ({
        color: '#B3BDCD',
        fontSize: theme.typography.caption.fontSize,
        marginBottom: theme.spacing(1),
        textTransform: 'uppercase',
    });
});
export var DateTimeInput = forwardRef(function (props, ref) {
    var _a = useState(''), value = _a[0], setValue = _a[1];
    var t = useTranslation().t;
    var format = t('dateTimeFormat.dateInput');
    useEffect(function () {
        var _a, _b;
        setValue((_b = (_a = props.value) === null || _a === void 0 ? void 0 : _a.format(format)) !== null && _b !== void 0 ? _b : '');
    }, [format, props.value]);
    var handleChange = function (e) {
        setValue(e.target.value);
    };
    var syncValue = function () {
        var _a;
        if (((_a = props.value) === null || _a === void 0 ? void 0 : _a.format(format)) !== value) {
            var validValue = value && moment(value).isValid() ? value : '';
            props.onChange(validValue);
            setValue(validValue);
        }
    };
    var handleBlur = function (e) {
        var _a;
        syncValue();
        (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, e);
    };
    return (_jsxs(Box, __assign({ flexGrow: 1 }, { children: [_jsx(StyledLabel, __assign({ sx: props.titleStyle }, { children: props.label })), _jsx(TextField, { fullWidth: true, value: value, inputRef: ref, onChange: handleChange, onBlur: handleBlur, onFocus: props.onFocus, error: !!props.error, helperText: props.error, onKeyDown: function (e) { return e.key === 'Enter' && syncValue(); }, placeholder: format })] })));
});
var DateTimeQuickSelectInput = function (props) {
    var theme = useTheme();
    var t = useTranslation('daterangepicker').t;
    var _a = useForm(useMemo(function () { return ({
        initialValues: {
            lastDay: DEFAULT_CUSTOM_QUICK_DAY.toString(),
        },
        restrictPattern: {
            lastDay: PATTERN_LAST_DATE_FIELD,
        },
    }); }, [])), values = _a.values, handleChange = _a.handleChange;
    var updateDateRange = useCallback(function (lastDay) {
        var _a;
        var value = parseInt((_a = lastDay !== null && lastDay !== void 0 ? lastDay : values.lastDay) !== null && _a !== void 0 ? _a : '', 10);
        if (value) {
            props.onChange([moment().subtract(value, 'day'), moment()]);
        }
    }, [props, values.lastDay]);
    return (_jsxs(StyledLastDaysContainer, { children: ["".concat(t('last')), _jsx(TextField, { name: "lastDay", value: values.lastDay, onChange: function (e) { return handleChange(e, undefined, updateDateRange); }, onClick: function () { return updateDateRange(); }, inputProps: { maxLength: 3, sx: { textAlign: 'center' } }, sx: { width: theme.typography.pxToRem(50) } }), "".concat(t('days'))] }));
};
export var CommonDateRangeFields = function (_a) {
    var fromDate = _a.fromDate, toDate = _a.toDate, maxDate = _a.maxDate, minDate = _a.minDate, setFieldValue = _a.setFieldValue, helperTextFromDate = _a.helperTextFromDate, helperTextToDate = _a.helperTextToDate, handleOnQuickSelect = _a.handleOnQuickSelect, onSubmit = _a.onSubmit, onCancel = _a.onCancel, popoverProps = _a.popoverProps, _b = _a.fromDateName, fromDateName = _b === void 0 ? 'fromDate' : _b, _c = _a.toDateName, toDateName = _c === void 0 ? 'toDate' : _c, minStartDate = _a.minStartDate, _d = _a.actionButtonDirection, actionButtonDirection = _d === void 0 ? 'row' : _d, props = __rest(_a, ["fromDate", "toDate", "maxDate", "minDate", "setFieldValue", "helperTextFromDate", "helperTextToDate", "handleOnQuickSelect", "onSubmit", "onCancel", "popoverProps", "fromDateName", "toDateName", "minStartDate", "actionButtonDirection"]);
    var theme = useTheme();
    var t = useTranslation(['daterangepicker', 'translation']).t;
    var prevStartDateRef = useRef();
    var startDateInputRef = useRef(null);
    var endDateInputRef = useRef(null);
    var datePickerRef = useRef(null);
    var maxEndDate = useMemo(function () { return moment().startOf('date').toDate(); }, []);
    var maxStartDate = useMemo(function () { return moment().startOf('date').subtract(1, 'day').toDate(); }, []);
    var _e = useState(false), isEndDateFocused = _e[0], setIsEndDateFocused = _e[1];
    useEffect(function () {
        var _a;
        (_a = startDateInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    useEffect(function () {
        if (fromDate) {
            prevStartDateRef.current = fromDate;
        }
    }, [fromDate]);
    useEffect(function () {
        var _a, _b, _c, _d;
        if (isEndDateFocused) {
            (_a = datePickerRef.current) === null || _a === void 0 ? void 0 : _a.setPreSelection((toDate === null || toDate === void 0 ? void 0 : toDate.toDate()) || ((_b = prevStartDateRef.current) === null || _b === void 0 ? void 0 : _b.toDate()));
        }
        else {
            (_c = datePickerRef.current) === null || _c === void 0 ? void 0 : _c.setPreSelection(((_d = prevStartDateRef.current) === null || _d === void 0 ? void 0 : _d.toDate()) || (toDate === null || toDate === void 0 ? void 0 : toDate.toDate()));
        }
    }, [toDate, fromDate, isEndDateFocused]);
    var toMomentDate = function (date) { return (date ? moment(date) : null); };
    var handleChangeStartDate = function (date) {
        var _a, _b, _c, _d, _e, _f;
        if ((maxStartDate && ((_a = toMomentDate(date)) === null || _a === void 0 ? void 0 : _a.isAfter(maxStartDate, 'date'))) ||
            (minStartDate && ((_b = toMomentDate(date)) === null || _b === void 0 ? void 0 : _b.isBefore(minStartDate, 'date')))) {
            date = (_d = (_c = prevStartDateRef.current) === null || _c === void 0 ? void 0 : _c.toISOString()) !== null && _d !== void 0 ? _d : '';
        }
        setFieldValue(toMomentDate(date), fromDateName);
        if (date && toDate && moment(date).isAfter(toDate, 'date')) {
            setFieldValue(null, toDateName);
            (_e = endDateInputRef.current) === null || _e === void 0 ? void 0 : _e.focus();
        }
        if (date) {
            (_f = endDateInputRef.current) === null || _f === void 0 ? void 0 : _f.focus();
        }
    };
    var handleChangeEndDate = function (date) {
        var _a, _b, _c, _d, _e;
        if ((maxEndDate && ((_a = toMomentDate(date)) === null || _a === void 0 ? void 0 : _a.isAfter(maxEndDate, 'date'))) ||
            (minStartDate && ((_b = toMomentDate(date)) === null || _b === void 0 ? void 0 : _b.isBefore(minStartDate, 'date')))) {
            date = (maxEndDate === null || maxEndDate === void 0 ? void 0 : maxEndDate.toISOString()) || '';
        }
        if ((_c = toMomentDate(date)) === null || _c === void 0 ? void 0 : _c.isBefore(prevStartDateRef.current, 'date')) {
            setFieldValue(toMomentDate(date), fromDateName);
            setFieldValue(null, toDateName);
            (_d = endDateInputRef.current) === null || _d === void 0 ? void 0 : _d.focus();
        }
        else {
            setFieldValue(toMomentDate(date), toDateName);
            if (date) {
                setFieldValue(prevStartDateRef.current, fromDateName);
                (_e = startDateInputRef.current) === null || _e === void 0 ? void 0 : _e.focus();
            }
        }
    };
    var handleStartDateFocus = function () {
        setIsEndDateFocused(false);
    };
    var handleEndDateFocus = function () {
        setIsEndDateFocused(true);
    };
    var formContent = (_jsx(StyledDateRangePicker, { children: _jsxs(Stack, __assign({ gap: 5, divider: _jsx(Divider, { orientation: "horizontal", flexItem: true }) }, { children: [_jsxs(Stack, __assign({ direction: 'row', gap: popoverProps ? 9.25 : 14, justifyContent: 'space-between' }, { children: [_jsxs(Stack, __assign({ gap: 9.25 }, { children: [_jsxs(Box, __assign({ display: "flex", gap: 9.25 }, { children: [_jsx(DateTimeInput, { label: "".concat(t('from')), value: fromDate, ref: startDateInputRef, onChange: handleChangeStartDate, error: helperTextFromDate, onFocus: handleStartDateFocus, onBlur: function () { var _a; return (_a = props.handleBlur) === null || _a === void 0 ? void 0 : _a.call(props, fromDateName); }, titleStyle: {
                                                fontSize: theme.typography.body4,
                                            } }), _jsx(DateTimeInput, { label: "".concat(t('to')), value: toDate, ref: endDateInputRef, onChange: handleChangeEndDate, error: helperTextToDate, onFocus: handleEndDateFocus, onBlur: function () { var _a; return (_a = props.handleBlur) === null || _a === void 0 ? void 0 : _a.call(props, toDateName); }, titleStyle: {
                                                fontSize: theme.typography.body4,
                                            } })] })), _jsx(ReactDatePicker, __assign({ startDate: fromDate === null || fromDate === void 0 ? void 0 : fromDate.toDate(), endDate: toDate === null || toDate === void 0 ? void 0 : toDate.toDate(), monthsShown: 2, inline: true, ref: datePickerRef }, (isEndDateFocused
                                    ? {
                                        maxDate: maxEndDate,
                                        selected: toDate === null || toDate === void 0 ? void 0 : toDate.toDate(),
                                        selectsEnd: true,
                                        onChange: function (date) { return handleChangeEndDate(date && moment(date).endOf('day')); },
                                    }
                                    : {
                                        maxDate: maxStartDate,
                                        selected: fromDate === null || fromDate === void 0 ? void 0 : fromDate.toDate(),
                                        selectsStart: true,
                                        onChange: function (date) { return handleChangeStartDate(date && moment(date).startOf('day')); },
                                    }), { minDate: minStartDate === null || minStartDate === void 0 ? void 0 : minStartDate.toDate() }))] })), _jsxs(Box, { children: [_jsx(StyledLabel, { children: "".concat(t('quickSelect')) }), _jsx(Stack, __assign({ spacing: 0.5 }, { children: QUICK_SELECT(handleOnQuickSelect, minDate, maxDate).map(function (_a) {
                                        var label = _a.label, onClick = _a.onClick, disabled = _a.disabled;
                                        return (_jsxs(Fragment, { children: [_jsx(StyledQuickOption, __assign({ color: "inherit", onClick: onClick, disabled: disabled }, { children: t(label) })), label === 'allData' && (_jsx(Divider, { variant: "middle", sx: {
                                                        marginTop: "".concat(theme.typography.pxToRem(8), " !important"),
                                                        marginBottom: "".concat(theme.typography.pxToRem(8), " !important"),
                                                    } }))] }, label));
                                    }) })), _jsx(DateTimeQuickSelectInput, { onChange: handleOnQuickSelect })] })] })), onSubmit && (_jsxs(Stack, __assign({ direction: actionButtonDirection, spacing: 3.5 }, { children: [_jsx(Button, __assign({ variant: "contained", type: "submit", onClick: onSubmit }, { children: "".concat(t('button.apply', { ns: 'translation' })) })), _jsx(Button, __assign({ variant: "outlined", color: "inherit", onClick: onCancel }, { children: "".concat(t('button.cancel', { ns: 'translation' })) }))] })))] })) }));
    return popoverProps ? (_jsx(Popover, __assign({ anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, onClose: onCancel }, popoverProps, { children: formContent }))) : (formContent);
};
