var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TextField, styled } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { cleanFormattingNumber } from '../utils';
var StyledTextField = styled(TextField)(function () { return ({
    '& .MuiInputBase-input': { textOverflow: 'ellipsis' },
}); });
var numberToString = function (value) { var _a; return (_a = value === null || value === void 0 ? void 0 : value.toString()) !== null && _a !== void 0 ? _a : ''; };
var stringToNumber = function (value) { return (value ? Number(value) : null); };
var formatNumberSeperator = function (val, decimal) {
    return val ? Number(cleanFormattingNumber(val)).toLocaleString() + decimal : '';
};
export var NumberInput = function (_a) {
    var showSeperator = _a.showSeperator, decimalPlaces = _a.decimalPlaces, onChange = _a.onChange, onBlur = _a.onBlur, props = __rest(_a, ["showSeperator", "decimalPlaces", "onChange", "onBlur"]);
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var decimal = useRef('');
    var updateValue = useCallback(function (val) {
        setValue(function (curr) {
            var newValue = typeof val === 'function' ? val(curr) : val;
            var parts = newValue.split('.');
            var numberPart = parts[0];
            decimal.current = parts.length > 1 ? '.' + parts[1] : '';
            return showSeperator ? formatNumberSeperator(numberPart, decimal.current) : newValue;
        });
    }, [showSeperator]);
    useEffect(function () {
        updateValue(function (curr) {
            var parsedValue = cleanFormattingNumber(curr);
            if (stringToNumber(parsedValue) === props.value)
                return curr;
            return numberToString(props.value);
        });
    }, [props.value, updateValue]);
    var handleChange = function (event) {
        var _a = event.target, newValue = _a.value, name = _a.name;
        var cleanedValue = cleanFormattingNumber(newValue, decimalPlaces);
        if (props.value !== cleanedValue) {
            updateValue(cleanedValue);
            onChange === null || onChange === void 0 ? void 0 : onChange({
                target: {
                    name: name,
                    value: stringToNumber(cleanedValue),
                },
            });
        }
    };
    var handleBlur = function (event) {
        var inputValue = event.target.value;
        var cleanedValue = inputValue ? cleanFormattingNumber(inputValue, decimalPlaces) : '';
        updateValue(cleanedValue);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(__assign(__assign({}, event), { target: __assign(__assign({}, event.target), { value: cleanedValue }) }));
    };
    return _jsx(StyledTextField, __assign({}, props, { value: value, onChange: handleChange, onBlur: handleBlur }));
};
