var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getIn, setIn, setNestedObjectValues } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
var getFirstErrorPath = function (errors, path) {
    if (path === void 0) { path = ''; }
    return _.reduce(errors, function (key, value, property) {
        if (key || !value)
            return key;
        if (typeof value === 'object') {
            return getFirstErrorPath(value, "".concat(path).concat(property, "."));
        }
        return "".concat(path).concat(property);
    }, '');
};
var isFirstError = function (errors, name) { return getFirstErrorPath(errors).startsWith(name); };
function castValues(values, validationSchema) {
    return !validationSchema ? values : validationSchema.cast(values);
}
function getErrors(validationSchema, values) {
    var parseErrors = function (validationError) {
        return validationError.inner.reduceRight(function (errors, error) { var _a; return setIn(errors, (_a = error.path) !== null && _a !== void 0 ? _a : '', error.errors[0]); }, {});
    };
    try {
        validationSchema === null || validationSchema === void 0 ? void 0 : validationSchema.validateSync(values, { abortEarly: false });
        return {};
    }
    catch (error) {
        return parseErrors(error);
    }
}
export var useForm = function (_a) {
    var initialValues = _a.initialValues, validationSchema = _a.validationSchema, restrictPattern = _a.restrictPattern;
    var _b = useState(initialValues), values = _b[0], setValues = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState({}), touched = _d[0], setTouched = _d[1];
    var _e = useState(false), isSubmitting = _e[0], setIsSubmitting = _e[1];
    var _f = useState(0), submitCount = _f[0], setSubmitCount = _f[1];
    var _g = useState(false), isDirty = _g[0], setIsDirty = _g[1];
    var isUnMount = useRef(false);
    var fieldRef = useRef({});
    var t = useTranslation().t;
    var initialValuesRef = useRef();
    useEffect(function () {
        setValues(initialValues);
        initialValuesRef.current = initialValues;
    }, [initialValues]);
    useEffect(function () {
        isUnMount.current = false;
        return function () {
            isUnMount.current = true;
        };
    }, []);
    var castedValues = useMemo(function () { return castValues(values, validationSchema); }, [validationSchema, values]);
    useEffect(function () {
        setErrors(getErrors(validationSchema, values));
        setIsDirty(!_.isEqual(castedValues, initialValuesRef.current));
    }, [castedValues, validationSchema, values]);
    var setFieldValue = useCallback(function (value, field) {
        setValues(function (prev) {
            var newValue = typeof value === 'function' ? value(prev[field]) : value;
            var updatedValues = setIn(prev, field, newValue);
            setErrors(getErrors(validationSchema, updatedValues));
            return updatedValues;
        });
    }, [validationSchema]);
    var setFieldTouched = useCallback(function (isTouched, field) {
        setTouched(function (prev) { return setIn(prev, field, isTouched); });
    }, []);
    var handleChange = useCallback(function (event, checked, callback) {
        var target = event.target;
        var name = target.name;
        var value = checked !== null && checked !== void 0 ? checked : target.value;
        var patternField = restrictPattern === null || restrictPattern === void 0 ? void 0 : restrictPattern[name];
        if (typeof value === 'string' && value && patternField && !patternField.test(value)) {
            return;
        }
        callback === null || callback === void 0 ? void 0 : callback(value);
        setFieldValue(value, name);
    }, [restrictPattern, setFieldValue]);
    var handleBlur = useCallback(function (event) {
        var name = event.target.name;
        setFieldTouched(true, name);
    }, [setFieldTouched]);
    var handleSubmit = useCallback(function (submitFormHandler) {
        var excludeErrorFields = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            excludeErrorFields[_i - 1] = arguments[_i];
        }
        return function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var formErrors, keyErrors, firstError;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        event === null || event === void 0 ? void 0 : event.preventDefault();
                        if (isSubmitting) {
                            return [2 /*return*/];
                        }
                        setSubmitCount(function (curr) { return curr + 1; });
                        setIsSubmitting(true);
                        setTouched(setNestedObjectValues(values, true));
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 4, 5]);
                        formErrors = _.omit(getErrors(validationSchema, values), excludeErrorFields);
                        keyErrors = Object.keys(formErrors);
                        if (!!keyErrors.length) return [3 /*break*/, 3];
                        return [4 /*yield*/, submitFormHandler(castedValues)];
                    case 2:
                        _b.sent();
                        setTouched({});
                        _b.label = 3;
                    case 3:
                        firstError = getFirstErrorPath(formErrors);
                        firstError && ((_a = getIn(fieldRef.current, firstError)) === null || _a === void 0 ? void 0 : _a.focus());
                        return [3 /*break*/, 5];
                    case 4:
                        if (!isUnMount.current) {
                            setIsSubmitting(false);
                        }
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
    }, [castedValues, isSubmitting, validationSchema, values]);
    var focusOnFirstError = useCallback(function () {
        var _a;
        var firstError = getFirstErrorPath(errors);
        firstError && ((_a = getIn(fieldRef.current, firstError)) === null || _a === void 0 ? void 0 : _a.focus());
    }, [errors]);
    var handleReset = useCallback(function (key, _values) {
        if (key) {
            setValues(function (prev) { return setIn(prev, key, _.get(_values !== null && _values !== void 0 ? _values : initialValues, "".concat(key))); });
            setTouched(function (prev) { return setIn(prev, key, undefined); });
        }
        else {
            setValues(_values !== null && _values !== void 0 ? _values : initialValues);
            setTouched({});
        }
    }, [initialValues]);
    var getError = useCallback(function (name) {
        return getIn(touched, name) ? getIn(errors, name) : undefined;
    }, [errors, touched]);
    var isFirstFieldError = useCallback(function (name) { return isFirstError(errors, name); }, [errors]);
    var registerField = useCallback(function (name, valProp) {
        var _a;
        if (valProp === void 0) { valProp = 'value'; }
        return (_a = {
                name: name
            },
            _a[valProp] = getIn(values, name),
            _a.onChange = handleChange,
            _a.onBlur = handleBlur,
            _a.error = !!getError(name),
            _a.helperText = t(getError(name)),
            _a.inputRef = function (elm) {
                fieldRef.current = setIn(fieldRef.current, name, elm);
            },
            _a.fullWidth = true,
            _a);
    }, [getError, handleBlur, handleChange, t, values]);
    return {
        errors: errors,
        values: values,
        handleChange: handleChange,
        handleBlur: handleBlur,
        setFieldValue: setFieldValue,
        setFieldTouched: setFieldTouched,
        handleSubmit: handleSubmit,
        isSubmitting: isSubmitting,
        handleReset: handleReset,
        getError: getError,
        isFirstFieldError: isFirstFieldError,
        submitCount: submitCount,
        isDirty: isDirty,
        hasError: !!Object.keys(errors).length,
        registerField: registerField,
        castedValues: castedValues,
        focusOnFirstError: focusOnFirstError,
    };
};
